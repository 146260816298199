import React, {useState} from 'react';
import {useTheme} from "@mui/material/styles";
import Chatbot from 'react-best-chatbot';
import chatbot from 'src/assets/icons/chatbot.png'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import {SpeedDial, SpeedDialAction} from "@mui/lab";
import Iconify from "../iconify";
import AppCalendar from "../../sections/overview/app/app-calendar";

export default function WhatsAppContainer() {
    const theme = useTheme();

    const [openDialogCalendar, setOpenDialogCalendar] = useState(false);

    const ACTIONS = [
        {
            key: "whatsapp",
            icon: <Iconify icon="ic:baseline-whatsapp" width={30} color="primary"/>,
            name: 'Whatsapp'
        },
        {key: "call", icon: <Iconify icon="ic:round-phone" width={30} color="primary"/>, name: 'Llamar'},
        {
            key: "calendar",
            icon: <Iconify icon="ic:baseline-calendar-month" width={30} color="primary"/>,
            name: 'Agendar llamada'
        }
    ]

    const handlers = {
        calendar: setOpenDialogCalendar,
    }

    const handleOpenDialog = (dialogName) => {
        handlers[dialogName](true)
    }

    const handleCloseDialog = (dialogName) => {
        handlers[dialogName](false)
    }

    const onAction = (action) => {
        if(action === "whatsapp"){
            const phoneNumber = '+525534955438';
            const message = 'Hola, estaría interesado en ...';

            const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

            window.open(whatsappLink, '_blank');

        // } else if(action === "call"){

        } else if (action === "calendar"){
            handleOpenDialog("calendar")
        }


    }

    const fabStyle = {
        position: 'fixed',
        bottom: 30,
        right: 30,
    }

    return (
        <>
        <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={fabStyle}
            icon={<Iconify icon="mdi:support" width={40}/>}
        >
            {ACTIONS.map((action) => (
                <SpeedDialAction
                    key={action.key}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={() => onAction(action.key)}
                />
            ))}
        </SpeedDial>

    <AppCalendar open={openDialogCalendar}
                 onClose={() => handleCloseDialog("calendar")} />
    </>
        /* <Fab color="success" aria-label="add" sx={fabStyle} onClick={handleFabClick}>
            <WhatsAppIcon sx={{ fontSize: 30 }}/>
        </Fab> */
    )
}
