import PropTypes from 'prop-types';
// @mui
import {alpha, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// hooks
import {useResponsive} from 'src/hooks/use-responsive';
// theme
import {bgGradient} from 'src/theme/css';
// components
import Logo from 'src/components/logo';
import PowerBy from "../../components/power-by/power-by";

// ----------------------------------------------------------------------

export default function AuthClassicLayout({children, title}) {

    const theme = useTheme();

    const mdUp = useResponsive('up', 'md');

    const renderLogo = (
        <Logo
            sx={{
                zIndex: 9,
                position: 'absolute',
                m: {xs: 2, md: 5},
            }}
        />
    );

    const renderContent = (
        <Stack
            sx={{
                width: 1,
                mx: 'auto',
                maxWidth: {xs: 100, md: 800, lg: 750},
                px: {xs: 5, md: 8, lg: 20},
                pt: {xs: 10, md: 12, lg: 22},
                pb: {xs: 15, md: 0, lg: 20},
            }}
        >
            {children}

        </Stack>
    );

    const renderSection = (
        <Stack
            flexGrow={1}
            spacing={5}
            alignItems="center"
            justifyContent="center"
            sx={{
                pt: 5,
                ...bgGradient({
                    color: alpha(
                        theme.palette.background.default,
                        theme.palette.mode === 'light' ? 0.88 : 0.94
                    ),
                    imgUrl: '/assets/background/overlay_2.jpg',
                }),
            }}
        >
            <Box
                component="img"
                src="/logo/AlethIA.png"
                sx={{width: 200, cursor: 'pointer'}}
            />
            <Stack spacing={3}>
                <Typography variant="h3" textAlign="center">
                    {title || '¡Bienvenido al portal del cliente!'}
                </Typography>
                <Stack direction="row"
                       justifyContent="center"
                       alignItems="center">
                    <Typography variant="body2">Estamos aquí para ayudarte, resuelve tu situación con nosotros</Typography>
                    {/* <Box
                        component="img"
                        src="/logo/AlethIA.png"
                        sx={{width: 80, ml: 1}}
                    /> */}
                </Stack>
            </Stack>
            <Box
                component="img"
                alt="auth"
                src='/assets/images/finance.webp'
                sx={{
                    maxWidth: {
                        xs: 480,
                        lg: 560,
                        xl: 720,
                    },
                }}
            />
            <PowerBy/>
        </Stack>
    );

    return (
        <Stack
            component="main"
            direction="row"
            sx={{
                minHeight: '100vh',
            }}
        >
            {/* {renderLogo} */}

            {mdUp && renderSection}

            {renderContent}
        </Stack>
    );
}

AuthClassicLayout.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
};
