import React from "react";
import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Stack from "@mui/material/Stack";
// components
import Main from './main';
import PowerBy from "../../components/power-by/power-by";

// ----------------------------------------------------------------------

export default function FullScreenLayout({children}) {

    return (
        <>
            <Stack direction="row" justifyContent="center" sx={{position: 'fixed', top: 30, left: 30}} spacing={2}>
                {/* <Box
                    component="img"
                    src="/logo/Diglo.png"
                    sx={{width: 120}}
                /> */}
                <Box
                    component="img"
                    src="/logo/AlethIA.png"
                    sx={{width: 100}}
                />
            </Stack>
            <Box
                sx={{
                    minHeight: 1,
                    display: 'flex',
                    flexDirection: {xs: 'column', lg: 'row'},
                }}
            >
                <Main>{children}</Main>
            </Box>
            <PowerBy/>
        </>
    );
}

FullScreenLayout.propTypes = {
    children: PropTypes.node,
};
