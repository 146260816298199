// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '',
  DASHBOARD: '/dashboard',
  ADMIN: '/admin',
  OVERVIEW: '/overview',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
    signup: `${ROOTS.AUTH}/signup`,
    forgotPassword: `${ROOTS.AUTH}/forgot-password`,
    newPassword: `${ROOTS.AUTH}/new-password`
  },
  // OVERVIEW
  overview: {
    root: ROOTS.OVERVIEW,
    onboarding: `${ROOTS.OVERVIEW}/onboarding`,
    validation: `${ROOTS.OVERVIEW}/validation`,
    verify: `${ROOTS.OVERVIEW}/verify`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    financial: `${ROOTS.DASHBOARD}/financial`,
    agreements: `${ROOTS.DASHBOARD}/agreements`,
    files: `${ROOTS.DASHBOARD}/files`,
  },
  // ADMIN
  admin: {
    root: ROOTS.ADMIN,
    tracking: {
      root: `${ROOTS.ADMIN}/tracking`,
      evolution: `${ROOTS.ADMIN}/tracking/evolution`,
      volumetry: `${ROOTS.ADMIN}/tracking/volumetry`,
    },
    client: {
      root: `${ROOTS.ADMIN}/clients`,
      upload: `${ROOTS.ADMIN}/clients/upload`,
      select: (id) => `${ROOTS.ADMIN}/clients/${id}`,
    },
  },
};
