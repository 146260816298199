import React from 'react';
import PropTypes from "prop-types";
// @mui
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import {useTheme} from "@mui/material/styles";
// components
import CalendarFormOnborning from 'src/sections/calendar/calendar-form-onborning';

// ----------------------------------------------------------------------

export default function AppCalendar({open, onClose, setSchedule, setOnSchedule, title = "Agendar una llamada"}) {

    const theme = useTheme();

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={open}
            onClose={onClose}
            transitionDuration={{
                enter: theme.transitions.duration.shortest,
                exit: theme.transitions.duration.shortest - 80,
            }}
        >
            <DialogTitle sx={{minHeight: 76}}>
                {title}
            </DialogTitle>

            <CalendarFormOnborning
                onClose={onClose}
                setSchedule={setSchedule}
                setOnSchedule={setOnSchedule}
            />
        </Dialog>
    );
}

AppCalendar.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    setSchedule: PropTypes.string,
    setOnSchedule: PropTypes.func,
    title: PropTypes.string,
};
