import React, { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard/layout';

// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const PageFinancial = lazy(() => import('src/pages/dashboard/financial'));
const PageFiles = lazy(() => import('src/pages/dashboard/files'));
const AgreementsPage = lazy(() => import('src/pages/dashboard/agreements'));


// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'financial', element: <PageFinancial /> },
      { path: 'files', element: <PageFiles /> },
      { path: 'agreements', element: <AgreementsPage /> }
    ],
  },
];
