import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DayTimePicker from '@mooncake-dev/react-day-time-picker';
import {useTheme} from '@mui/material/styles';
import DialogActions from '@mui/material/DialogActions';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
// components
import FormProvider from 'src/components/hook-form';
import {useLocales} from 'src/components/locales';
// utils
import {fTimestamp} from '../../utils/format-time';

// ----------------------------------------------------------------------

export default function CalendarFormOnborning({onClose, setSchedule, setOnSchedule}) {
    const {currentLang} = useLocales();
    const theme = useTheme();

    const EventSchema = Yup.object().shape({
        description: Yup.string().required('La descripción es requerida.').max(5000, 'La descripción debe tener como máximo 5000 caracteres.'),
        start: Yup.mixed(),
    });

    const methods = useForm({
        resolver: yupResolver(EventSchema),
        defaultValues: {
            "description": "",
            "start": (() => {
                const currentDate = new Date();
                const minutes = currentDate.getMinutes();
                if (minutes < 30) {
                    currentDate.setMinutes(0);
                } else {
                    currentDate.setMinutes(30);
                }
                return currentDate.toISOString();
            })(),
        },
    });

    const {
        handleSubmit,
    } = methods;

    const onSubmit = handleSubmit(async (data) => {
        if (setOnSchedule) {
            setOnSchedule(true)
        }
        onClose()
    });

    const handleScheduled = dateTime => {
        if (setSchedule) {
            setSchedule(fTimestamp(dateTime))
        }
        if (setOnSchedule) {
            setOnSchedule(true)
        }
        onClose()
    };

    const onClickText = () => {
        const changeButtonText = () => {
            const button = document.querySelector('.sc-bxivhb.cVFxpO');
            if (button) {
                button.innerText = 'Ir atrás';
            }
        };

        setTimeout(changeButtonText, 0);
    }

    const timeSlotValidator = (slotTime) => {
        const startTime = new Date(
            slotTime.getFullYear(),
            slotTime.getMonth(),
            slotTime.getDate(),
            8,
            0,
            0
        );

        const endTime = new Date(
            slotTime.getFullYear(),
            slotTime.getMonth(),
            slotTime.getDate(),
            22,
            0,
            0
        );

        return slotTime >= startTime && slotTime < endTime;
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={currentLang.adapterLocale}>
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <Stack spacing={3}
                       sx={{
                           // mx: 5,
                           px: 2,
                           fontSize: 12
                       }}
                       onClick={onClickText}
                >

                    <DayTimePicker
                        timeSlotSizeMinutes={30}
                        onConfirm={handleScheduled}
                        timeSlotValidator={timeSlotValidator}
                        doneText="Ok"
                        loadingText="Cargando..."
                        confirmText="Confirmar cita"
                        theme={{
                            primary: theme.palette.primary.main,
                            secondary: '#f0f0f0',
                            background: '#fff',
                            buttons: {
                                disabled: {
                                    color: '#333',
                                    background: '#dfdfdf'
                                },
                                confirm: {
                                    color: '#fff',
                                    background: theme.palette.primary.main,
                                    hover: {
                                        color: '',
                                        background: theme.palette.secondary.main
                                    }
                                }
                            },
                            feedback: {
                                success: {
                                    color: '#29aba4'
                                },
                                failed: {
                                    color: '#eb7260'
                                }
                            }
                        }}
                    />
                </Stack>
                <DialogActions>
                    <Box sx={{flexGrow: 1}}/>
                    <Button variant="outlined" color="inherit" onClick={onClose}>
                        Cancelar
                    </Button>
                </DialogActions>
            </FormProvider>
        </LocalizationProvider>
    );
}

CalendarFormOnborning.propTypes = {
    onClose: PropTypes.func,
    setSchedule: PropTypes.func,
    setOnSchedule: PropTypes.func
};
