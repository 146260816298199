import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
// components
import { SplashScreen } from 'src/components/loading-screen';

import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';

// ----------------------------------------------------------------------

// auth
const LoginPage = lazy(() => import('src/pages/auth/login'));
const AdminLoginView = lazy(() => import('src/pages/auth/admin-login'));
const RegisterPage = lazy(() => import('src/pages/auth/register'));
const ForgotPasswordModernPage = lazy(() => import('src/pages/auth/forgot-password'));
const NewPasswordModernPage = lazy(() => import('src/pages/auth/new-password'));

// ----------------------------------------------------------------------

const auth = {
  path: '',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout>
          <LoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'admin/login',
      element: (
        <AuthClassicLayout>
          <AdminLoginView />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'signup',
      element: (
        <AuthClassicLayout>
          <RegisterPage />
        </AuthClassicLayout>
      ),
    },
    {
      element: (
        <AuthModernCompactLayout>
          <Outlet />
        </AuthModernCompactLayout>
      ),
      children: [
        { path: 'forgot-password', element: <ForgotPasswordModernPage /> },
        { path: 'new-password', element: <NewPasswordModernPage /> }
      ],
    },
  ],
};

export const authRoutes = [
  {
    path: '',
    children: [auth],
  },
];
