import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import AdminDashboardLayout from 'src/layouts/admin';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/admin/app'));
const UploadPage = lazy(() => import('src/pages/admin/upload'));
const AccountPage = lazy(() => import('src/pages/admin/user/account'));
const EvolutionPage = lazy(() => import('src/pages/admin/tracking/evolution'));
const VolumetryPage = lazy(() => import('src/pages/admin/tracking/volumetry'));

// ----------------------------------------------------------------------

export const adminDashboardRoutes = [
  {
    path: 'admin',
    element: (
      <AuthGuard>
        <AdminDashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </AdminDashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'tracking',
        children: [
          { path: 'evolution', element: <EvolutionPage /> },
          { path: 'volumetry', element: <VolumetryPage /> },
        ],
      },
      {
        path: 'clients',
        children: [
          { element: <IndexPage />, index: true },
          { path: 'upload', element: <UploadPage /> },
          { path: ':id', element: <AccountPage /> },
        ],
      },
    ],
  },
];
