import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';
//
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';
const STORAGE_USER_KEY = 'user';


export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);
      const userData = sessionStorage.getItem(STORAGE_USER_KEY)
      
      if (userData && accessToken && isValidToken(accessToken)) {
        const dataUser = JSON.parse(userData);
        setSession(accessToken, dataUser);
        
        dispatch({
          type: 'INITIAL',
          payload: {
            user: {
              ...dataUser,
              accessToken,
            },
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (data) => {

    const response = await axios.post(endpoints.api.auth.login, data, {
      headers: { Content_type: "application/json" },
    });

    const { user, accessToken } = response.data;
    setSession(accessToken, user);

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          ...user,
          accessToken,
        },
      },
    });

    return user
  }, []);

  // LOGIN MOMENTANEO
  const loginAdmin = useCallback(async (data) => {

    const response = await axios.post(endpoints.auth.login, {"email": "demo@minimals.cc", "password": "demo1234"});
    const { accessToken } = response.data;
    const user = {
      "id": "8864c717-587d-472a-929a-8e5f298024da-0",
      "displayName": "Admin Alethia",
      "email": "admin@alethia.com",
      "password": "",
      "photoURL": "https://simplai.es/wp-content/uploads/2023/05/alethia.png",
      "phoneNumber": "+40 777666555",
      "country": "United States",
      "address": "90210 Broadway Blvd",
      "state": "California",
      "city": "San Francisco",
      "zipCode": "94116",
      "about": "Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.",
      "role": "admin",
      "isPublic": true
    }

    setSession(accessToken, user);

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          ...user,
          accessToken,
        },
      },
    });

    return user
  }, []);

  // REGISTER
  const register = useCallback(async (data, validation = false) => {
    let URL = endpoints.api.auth.signup;

    if (validation) {
      URL = endpoints.api.auth.validation
    }
    const response = await axios.post(URL, data);
    const { user, accessToken } = response.data;

    setSession(accessToken, user);

    dispatch({
      type: 'REGISTER',
      payload: {
        user: {
          ...user,
          accessToken,
        },
      },
    });

    return user
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      loginAdmin,
      register,
      logout,
    }),
    [login, loginAdmin, logout, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
