/* eslint-disable no-else-return */
import {useMemo} from 'react';
// routes
import {paths} from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
    <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{width: 1, height: 1}}/>
);

const ICONS = {
    job: icon('ic_job'),
    blog: icon('ic_blog'),
    chat: icon('ic_chat'),
    mail: icon('ic_mail'),
    user: icon('ic_user'),
    file: icon('ic_file'),
    lock: icon('ic_lock'),
    tour: icon('ic_tour'),
    order: icon('ic_order'),
    label: icon('ic_label'),
    blank: icon('ic_blank'),
    kanban: icon('ic_kanban'),
    folder: icon('ic_folder'),
    banking: icon('ic_banking'),
    booking: icon('ic_booking'),
    invoice: icon('ic_invoice'),
    product: icon('ic_product'),
    calendar: icon('ic_calendar'),
    disabled: icon('ic_disabled'),
    external: icon('ic_external'),
    menuItem: icon('ic_menu_item'),
    ecommerce: icon('ic_ecommerce'),
    analytics: icon('ic_analytics'),
    dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData(unnax, unnaxAfter, files, refinance) {

    const data = useMemo(
        () => {
            const pathsNav = [{
                subheader: '',
                items: [
                    {title: 'Posición global', path: paths.dashboard.root, icon: ICONS.banking},
                ],
            }]

            if (unnax || unnaxAfter) {
                pathsNav[0].items.push({
                    title: 'Mi situación financiera',
                    path: paths.dashboard.financial,
                    icon: ICONS.analytics,
                });
            }
            else{
                pathsNav[0].items.push({
                    title: 'Mi situación financiera',
                    path: paths.dashboard.financial,
                    icon: ICONS.analytics,
                    disabled: true,
                    tooltip: "Agrega tus cuentas bancarias y te mostraremos un resumen de tu situación financiera."
                });
            }

            if (files) {
                pathsNav[0].items.push({
                    title: 'Mis documentos cargados',
                    path: paths.dashboard.files,
                    icon: ICONS.mail,
                });
            } else {
                pathsNav[0].items.push({
                    title: 'Mis documentos cargados',
                    path: paths.dashboard.files,
                    icon: ICONS.mail,
                    disabled: true,
                    tooltip: "Carga tus documentos oficiales y aquí podrás visualizarlos"
                });
            }

            if (refinance) {
                pathsNav[0].items.push({
                    title: 'Acuerdo en curso',
                    path: paths.dashboard.agreements,
                    icon: ICONS.file
                });
            } else {
                pathsNav[0].items.push({
                    title: 'Acuerdo en curso',
                    path: paths.dashboard.agreements,
                    icon: ICONS.file,
                    disabled: true,
                    tooltip: "Aquí podrás hacer seguimiento del estado de avance de tus acuerdos en curso"
                });
            }

            return pathsNav
        },

        [unnax, unnaxAfter, files, refinance]
    );

    return data;
}
