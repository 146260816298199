import React, { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import FullScreenLayout from 'src/layouts/full_screen/layout';

// components
import { LoadingScreen } from 'src/components/loading-screen';
import WhatsAppContainer from 'src/components/chatbot/whatsapp';
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';


// ----------------------------------------------------------------------

const PagePayment = lazy(() => import('src/pages/dashboard/payment'));
const PagePaymentMonthly = lazy(() => import('src/pages/dashboard/payment-monthly'));
const PageOnboarding = lazy(() => import('src/pages/dashboard/onboarding'));
const PageValidation = lazy(() => import('src/pages/dashboard/validation'));
const VerifyModernPage = lazy(() => import('src/pages/dashboard/verify'));

// ----------------------------------------------------------------------

export const fullScreenRoutes = [
  {
    path: 'overview',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Outlet />
        <WhatsAppContainer/>
      </Suspense>
    ),
    children: [
      {
        path: 'payment',
        element: (
          <AuthGuard>
            <FullScreenLayout>
              <PagePayment />
            </FullScreenLayout>
          </AuthGuard>
        ),
      },
      {
        path: 'payment-monthly',
        element: (
          <AuthGuard>
            <FullScreenLayout>
              <PagePaymentMonthly />
            </FullScreenLayout>
          </AuthGuard>
        ),
      },
      {
        path: 'onboarding',
        element: (
          <AuthGuard>
            <FullScreenLayout>
              <PageOnboarding />
            </FullScreenLayout>
          </AuthGuard>
        ),
      },
      {
        path: 'validation',
        element: (
          <FullScreenLayout>
            <PageValidation />
          </FullScreenLayout>
        ),
      },
      {
        path: 'verify',
        element: (
          <AuthModernCompactLayout>
            <VerifyModernPage />
          </AuthModernCompactLayout>
        ),
      },
    ],
  },
];
