import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  // (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------
const BASE_API = process.env.REACT_APP_URL_API

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  api:{
    auth: {
      login: `${BASE_API}/auth/login`,
      signup: `${BASE_API}/auth/signup`,
      validation: `${BASE_API}/auth/validation`,
    },
    client: {
      search: (id) => `${BASE_API}/clients/${id}`,
      info: (id) => `${BASE_API}/clients/list/${id}`,
      list: `${BASE_API}/clients/list`,
      upsert: `${BASE_API}/clients/upsert`,
      checkout: `${BASE_API}/clients/checkout`,
      unnax: `${BASE_API}/clients/getUnnaxToken`
    },
    graphers: {
      dates: (type) => `${BASE_API}/graphers/dates/${type}`,
      graphers: (type, start, end) => {
        if (end) {
          return `${BASE_API}/graphers/${type}/${start}/${end}`;
        }
        
        return `${BASE_API}/graphers/${type}/${start}`;
      }
    }
  }
};
